import { HeroInside } from '@/v2/components/molecules/hero-inside/hero-inside'
import Link from 'next/link'
import s from './not-found-hero.module.css'
import { PROD_URL, ROUTES } from '@/constants'
import cx from 'classnames'

type Props = {
  errorCode: string
}

export const NotFoundHero = ({ errorCode }: Props) => {
  const content = (
    <div className={s.heroCard}>
      <h1 className={s.heroCardHeader}>Whoops!</h1>
      <p className={cx(s.heroCardText, s.heroCardTextUpper)}>{errorCode}.</p>
      <p className={s.heroCardText}>
        Looks like the bouquet toss went wild! We couldn&apos;t find the page
        you&apos;re looking for amidst all the petals. Try heading back to the
        dance floor, aka our homepage, for some inspiration that will really get
        you in the groove.
      </p>
      <div className={s.heroLinks}>
        <Link href={ROUTES.HOME} className={s.linkButton}>
          <span>Go to homepage</span>
        </Link>
        <Link href={ROUTES.WEDDING_INSPIRATION} className={s.linkButton}>
          <span>Get inspired</span>
        </Link>
        <Link href={ROUTES.SIGN_UP} className={s.linkButton}>
          <span>Sign up and start planning</span>
        </Link>
      </div>
    </div>
  )

  return (
    <HeroInside
      imageSrc={`${PROD_URL}/assets/images/hero-not-found.png`}
      imageAlt={'Hero Not Found'}
      content={content}
      imageLoading={'eager'}
    />
  )
}
