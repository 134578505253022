'use client'

import { api } from '@/api'
import { ErrorCode } from '@/errors/errorCode'
import { Article } from '@/types/Article'
import { SectionContainer } from '@/v2/components/atoms/section-container/section-container'
import { NotFoundContent } from '@/v2/components/pages/not-found/not-found-content/not-found-content'
import { NotFoundHero } from '@/v2/components/pages/not-found/not-found-hero/not-found-hero'
import { useEffect, useState } from 'react'

export default function Error({
  error,
}: {
  error: Error & { digest?: string }
}) {
  const [articles, setArticles] = useState<Article[]>([])

  useEffect(() => {
    const fetchMostRecentInspirationArticles = async () => {
      const [aErr, articles] = await api.fe.article.search(
        {
          pageSize: 15,
          sort: 'published_at_desc',
          type: 'inspiration',
        },
        { next: { revalidate: 60 * 5 } }
      )

      if (aErr) {
        console.log(ErrorCode.e00007, aErr)
        return
      }

      setArticles(articles.results)
    }

    fetchMostRecentInspirationArticles()
  }, [])

  return (
    <>
      <SectionContainer>
        <NotFoundHero errorCode="500" />
      </SectionContainer>

      <SectionContainer sliderSection style={{ marginTop: '16px' }}>
        <NotFoundContent articles={articles} />
      </SectionContainer>
    </>
  )
}
